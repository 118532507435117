import React from "react"
import styles from "./button.module.scss"

const Button = ({
  handleClick,
  variant = "primary",
  type = "button",
  children,
}) => {
  return (
    <button
      onClick={handleClick && handleClick}
      type={type}
      className={`${styles.button} ${styles[variant]}`}
    >
      {children}
    </button>
  )
}

export default Button
