import React from "react"
import Cookies from "js-cookies"
import CookieConsentStyles from "./src/styles/cookieConsent.module.scss"
import Button from "./src/components/global/button"

const analyticsCookie = "gatsby-gdpr-google-analytics"
const overlayRef = React.createRef()
const hideOverlay = () => (overlayRef.current.style.display = "none")

export const wrapPageElement = ({ element, props }) => (
  <>
    {element}
    {Cookies.getItem(analyticsCookie) === null && (
      <div ref={overlayRef} className={CookieConsentStyles.overlay}>
        <div className={CookieConsentStyles.container}>
          <div className={CookieConsentStyles.text}>
            Wir verwenden Cookies, um die Zugriffe auf unsere Website zu
            analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung
            unserer Website an unsere Partner für Analysen weiter. Unsere
            Partner führen diese Informationen möglicherweise mit weiteren Daten
            zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen
            Ihrer Nutzung der Dienste gesammelt haben.
          </div>
          <div className={CookieConsentStyles.buttons}>
            <Button
              handleClick={() => {
                Cookies.setItem(analyticsCookie, true, 365, "/")
                hideOverlay()
              }}
              className={CookieConsentStyles.button}
            >
              Cookies akzeptieren
            </Button>
            <Button
              handleClick={() => {
                Cookies.setItem(analyticsCookie, false, "Session", "/")
                hideOverlay()
              }}
              variant="secondary"
              className={CookieConsentStyles.button}
            >
              Cookies ablehnen
            </Button>
          </div>
        </div>
      </div>
    )}
  </>
)
